.purchase-credits-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem;
  width: 800px;
  color: #333;
  position: relative;
  display: flex;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2rem;
  color: #4caf50;
  cursor: pointer;
}

.modal-body {
  display: flex;
  width: 100%;
  align-items: center;
}

.modal-image {
  flex: 1;
  margin-right: 1rem;
}

.modal-image img {
  max-width: 100%;
  border-radius: 8px;
}

.modal-text {
  flex: 1;
}

.modal-text h2 {
  margin: 0;
  font-size: 2rem;
  color: black;
}

.modal-text p {
  font-size: 1.5rem;
  margin: 0.5rem 0 1rem;
}

.features {
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
}

.features li {
  margin-bottom: 0.5rem;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.buy-button, .free-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.free-button {
  background-color: #555;
}

.plan-toggle {
display: flex;
align-items: center;
cursor: pointer;
}

.toggle {
width: 60px;
height: 30px;
background-color: #ccc;
border-radius: 15px;
position: relative;
transition: background-color 0.3s;
}

.toggle.active {
background-color: #4caf50; /* Green when active */
}

.toggle-indicator {
width: 26px;
height: 26px;
background-color: white;
border-radius: 50%;
position: absolute;
top: 2px;
left: 2px;
transition: transform 0.3s;
}

.toggle.active .toggle-indicator {
transform: translateX(30px); /* Move to the right when active */
}

.toggle-label {
margin-left: 10px;
font-weight: bold;
}

.buy-button:hover {
  background-color: #5cb85c; /* Lighter green on hover */
  transform: scale(1.05); /* Slightly enlarge the button */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for effects */
}

