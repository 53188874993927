@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@layer base {
  :root {
    --color-main: #2563eb;
    --color-secondary: #64748b;
    --color-success: #22c55e;
    --color-danger: #ef4444;
    --color-warning: #f59e0b;
  }
  
  body {
    @apply text-gray-800 bg-gray-50 antialiased;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded-lg font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2;
  }
  
  .btn-primary {
    @apply bg-mainColor text-white hover:bg-mainColor/90 focus:ring-mainColor/50;
  }
  
  .btn-secondary {
    @apply bg-gray-200 text-gray-800 hover:bg-gray-300 focus:ring-gray-200;
  }
  
  .input {
    @apply w-full px-4 py-2 rounded-lg border border-gray-300 focus:border-mainColor focus:ring-1 focus:ring-mainColor focus:outline-none transition-colors duration-200;
  }
  
  .label {
    @apply block text-sm font-medium text-gray-700 mb-1;
  }
  
  .card {
    @apply bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200;
  }
}

:root {
  --font-primary: "Poppins", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.slimScrollbar::-webkit-scrollbar {
  width: 5px; /* Adjust scrollbar width */
}

.slimScrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background, change if needed */
}

.slimScrollbar::-webkit-scrollbar-thumb {
  background: #74a567; /* Scrollbar color */
  border-radius: 4px; /* Scrollbar radius */
}

.slimScrollbar::-webkit-scrollbar-thumb:hover {
  background: #74a567; /* Scrollbar hover color, slightly darker for visual feedback */
}

.noScroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.noScroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.ant-btn-primary{
  background-color: #2563eb !important;
}
