.progress { 
    background-color: #e6f0e3; 
    width: 100%; 
    border-radius: 15px; 
    margin: 5px 0px 5px 0px;
  } 
  
  .progressbar { 
    background-color: rgb(116, 194, 92); 
    color: white; 
    padding: 1%; 
    text-align: right; 
    border-radius: 15px; 
    transition: all 1s ease-in-out;
  } 

  #progressstatus{
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 500;
  }
  
  #progresspercent{
    font-size: 15px;
    font-weight: 500;
    color: #595555;
  }

  #inProgressPanel{
    width: 28vw;
    position: absolute;
    bottom: 0vh;
    z-index: 4;
  }

button:disabled,
button[disabled]{
  opacity: 0.7;
}