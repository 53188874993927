/* Overlay background */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Subtle dark background for focus */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal container */
.modal-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 90%;
  padding: 25px;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  animation: scaleIn 0.3s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

/* Modal title */
.modal-title {
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.3;
}

/* Modal message */
.modal-message {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
  font-weight: 400;
  text-align: center;
}

/* Modal note (Support contact info) */
.modal-note {
  font-size: 1rem;
  color: #74a567; /* A bright, professional color for links */
  margin-bottom: 30px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s ease;
}

.modal-note:hover {
  color: #416138; /* Darken on hover */
}

/* Button styles */
.modal-link,
.modal-close-button {
  background-color: #74a567;
  color: #fff;
  border: none;
  padding: 14px 30px;
  margin: 10px;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Button hover effects */
.modal-link:hover,
.modal-close-button:hover {
  /* background-color: #0056b3; */
  transform: translateY(-2px);
}

/* Modal close button (X) for accessibility */
.modal-close-button {
  background-color: transparent;
  color: #999;
  /* font-size: 1.6rem; */
  padding: 0;
  /* position: absolute; */
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.modal-close-button:hover {
  color: #999;
  transform: scale(1.1);
}

/* Responsive adjustments for smaller screens */
@media (max-width: 480px) {
  .modal-container {
    width: 90%;
    padding: 15px;
  }

  .modal-title {
    font-size: 1.4rem;
  }

  .modal-message,
  .modal-note {
    font-size: 0.95rem;
  }

  .modal-link,
  .modal-close-button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}
